<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          {{ greeting }}, {{ userInfo.name }}
        </h2>
      </v-col>
    </v-row>
    <v-expand-transition>
      <PendingPlanChangeAlert />
    </v-expand-transition>
    <v-expand-transition>
      <ScheduledPlanChangeAlert ref="scheduledPlanChangeAlertRef" />
    </v-expand-transition>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card class="status-card">
          <v-list-item>
            <v-list-item-content>
              <!-- Adjust typography here for consistency -->
              <v-list-item-title class="subtitle-1 font-weight-bold mb-2">
                {{ planName }} Plan
              </v-list-item-title>
              <v-list-item-subtitle>
                <div class="price-validity">
                  <span class="price">
                    Ksh {{ customer.customer_plan.internet_plan.price | formatCurrency }}
                  </span>
                  <span class="validity">
                    /{{ formatBillingInterval(customer.customer_plan.internet_plan.validity_unit, customer.customer_plan.internet_plan.validity) }}
                  </span>
                </div>
                <div class="expiry-date">
                  <span>Expiry: </span>
                  <span :class="`${planBadgeColor}--text font-weight-medium`">
                    {{ customer.customer_plan.effective_end_date | formatDate }}
                  </span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <!-- Other cards remain unchanged -->
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card class="status-card">
          <v-list-item>
            <v-list-item-avatar
              :color="customer.has_internet_access ? 'success' : 'error'"
              class="status-indicator"
            >
              <v-icon
                v-if="!isLoading"
                color="white"
              >
                {{ internetStatusIcon }}
              </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                Internet Status
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  :class="{
                    'success--text': customer.has_internet_access,
                    'error--text': !customer.has_internet_access
                  }"
                >
                  {{ customer.has_internet_access ? 'ONLINE' : 'OFFLINE' }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card class="status-card">
          <v-list-item>
            <v-list-item-avatar
              color="success"
              class="status-indicator"
            >
              <v-icon
                v-if="!isLoading"
                color="white"
              >
                {{ icons.credit }}
              </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                Account Credit
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="amber--text font-weight-bold">
                  Ksh {{ customer.credits | formatCurrency }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-card class="status-card">
          <v-list-item>
            <v-list-item-avatar
              color="error"
              class="status-indicator"
            >
              <v-icon
                v-if="!isLoading"
                color="white"
              >
                {{ icons.alert }}
              </v-icon>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-bold">
                Outstanding Debt
              </v-list-item-title>
              <v-list-item-subtitle>
                <span
                  class="font-weight-bold"
                  :class="{
                    'error--text': customer.debt > 0
                  }"
                >
                  Ksh {{ customer.debt | formatCurrency }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        md="12"
        class="mt-6"
      >
        <internet-traffic-details
          :is-loading="isLoading"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="text-center mt-6 mb-4"
      >
        <v-btn
          large
          color="success"
          class="mx-2 mb-6"
          @click="initiatePayment"
        >
          <v-icon left>
            {{ icons.cash }}
          </v-icon>Make Payment
        </v-btn>
        <v-btn
          large
          color="info"
          class="mx-2 mb-6"
          @click="changeInternetPlan"
        >
          <v-icon left>
            {{ icons.changePlan }}
          </v-icon>Change Internet Plan
        </v-btn>
        <v-btn
          large
          color="secondary"
          class="mx-2 mb-6"
          @click="goToSettings"
        >
          <v-icon left>
            {{ icons.accountCircle }}
          </v-icon>Update Profile
        </v-btn>
        <v-btn
          large
          color="info"
          class="mx-2 mb-6"
          @click="viewPaymentHistory"
        >
          <v-icon left>
            {{ icons.paymentHistory }}
          </v-icon>Payment History
        </v-btn>
      </v-col>
    </v-row>
    <initiate-mpesa-payment-dialog
      :show-dialog="showPaymentDialog"
      :contact-number="phoneNumber"
      @close="showPaymentDialog = false"
    />
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import {
  mdiWeb, mdiWifi, mdiWifiOff, mdiCash, mdiAccountCircle, mdiFinance,
  mdiSwapHorizontal, mdiHistory, mdiAlertCircle, mdiSpeedometer,
} from '@mdi/js'
import InitiateMpesaPaymentDialog from '@/components/dialogs/InitiateMpesaPaymentDialog.vue'
import PendingPlanChangeAlert from '@/components/partials/PendingPlanChangeAlert.vue'
import ScheduledPlanChangeAlert from '@/components/partials/ScheduledPlanChangeAlert.vue'
import InternetTrafficDetails from '@/components/details-card/InternetTrafficDetails.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import speedUnit from '@/mixins/speedUnit'
import customerPlanEnum from '@/enums/customerPlanEnum'

export default {
  components: {
    InitiateMpesaPaymentDialog,
    PendingPlanChangeAlert,
    ScheduledPlanChangeAlert,
    InternetTrafficDetails,
  },
  mixins: [billingIntervalName, speedUnit],
  data() {
    return {
      cardMaxWidth: '400',
      isLoading: false,
      showPaymentDialog: false,
      phoneNumber: '',
      customer: {
        customer_plan: {
          internet_plan: {},
        },
      },
      icons: {
        web: mdiWeb,
        credit: mdiAccountCircle,
        wifi: mdiWifi,
        wifiOff: mdiWifiOff,
        cash: mdiCash,
        accountCircle: mdiAccountCircle,
        debt: mdiFinance,
        changePlan: mdiSwapHorizontal,
        paymentHistory: mdiHistory,
        alert: mdiAlertCircle,
        speedometer: mdiSpeedometer,
      },

    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    greeting() {
      const currentHour = new Date().getHours()
      if (currentHour < 12) {
        return 'Good Morning'
      }
      if (currentHour < 18) {
        return 'Good Afternoon'
      }

      return 'Good Evening'
    },
    internetStatusColor() {
      return this.customer.has_internet_access ? 'success' : 'warning'
    },
    internetStatusIcon() {
      return this.customer.has_internet_access ? this.icons.wifi : this.icons.wifiOff
    },
    planName() {
      return this.customer?.customer_plan?.internet_plan?.name || 'N/A'
    },
    planSpeed() {
      return this.customer?.customer_plan?.internet_plan?.download_speed
        ? `${this.customer.customer_plan.internet_plan.download_speed}${this.speedUnit}`
        : 'N/A'
    },
    speedUnit() {
      return 'Mbps' // You can make this dynamic if needed
    },
    formattedPrice() {
      return this.customer?.plan_price
        ? this.formatCurrency(this.customer.plan_price)
        : '0'
    },
    billingInterval() {
      const plan = this.customer?.customer_plan?.internet_plan

      return plan
        ? this.formatBillingInterval(plan.validity_unit, plan.validity)
        : 'N/A'
    },
    formattedExpiryDate() {
      return this.customer?.expiry_date
        ? this.formatDate(this.customer.expiry_date)
        : 'N/A'
    },
    isSpeedInName() {
      const speedWithUnit = this.customer?.customerPlan?.internet_plan?.download_speed
    + (this.speedUnit ?? '')
    || ''

      return this.customer?.customerPlan?.internet_plan?.name?.includes(speedWithUnit) ?? false
    },
    planBadgeStatus() {
      switch (this.customer.customer_plan.status) {
        case customerPlanEnum.EXPIRED:
          return 'Expired'
        case customerPlanEnum.ACTIVE:
          return 'Active'
        case customerPlanEnum.INACTIVE:
          return 'Inactive'
        case customerPlanEnum.CANCELED:
          return 'Canceled'
        default:
          return 'Unknown'
      }
    },
    planBadgeColor() {
      switch (this.customer.customer_plan.status) {
        case customerPlanEnum.EXPIRED:
          return 'warning'
        case customerPlanEnum.ACTIVE:
          return 'success'
        case customerPlanEnum.INACTIVE:
          return 'warning'
        case customerPlanEnum.CANCELED:
          return 'error'
        default:
          return 'primary'
      }
    },
  },
  mounted() {
    this.fetchCustomer()
  },
  methods: {
    fetchCustomer() {
      this.isLoading = true
      axios.get('/customer')
        .then(response => {
          this.customer = response.data.customer
          this.phoneNumber = response.data.phone_number
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    initiatePayment() {
      this.showPaymentDialog = true
    },
    goToSettings() {
      this.$router.push({ name: 'settings' })
    },
    changeInternetPlan() {
      this.$router.push({ name: 'internet-plans', params: { type: 1 } })
    },
    viewPaymentHistory() {
      this.$router.push({ name: 'payments' })
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 1.25rem;
}
.subtitle {
  font-size: 1rem;
}
.status-card {
  border-radius: 8px;
}
.status-indicator {
  margin-right: 16px;
}
.status-text {
  font-size: 1.5rem;
  font-weight: bold;
}
.internet-plan-card {
  border-radius: 8px;
  position: relative;
}
.badge-right {
  position: absolute;
  top: 0;
  right: 0;
}
.plan-name {
  font-size: 1.2rem;
  font-weight: bold;
}
.plan-details {
  padding-top: 8px;
}
.speed {
  font-size: 1.1rem;
  margin-bottom: 8px;
}
.price-validity {
  display: flex;
  align-items: baseline;
}
.price {
  font-size: 1.0rem;
  font-weight: bold;
}
.validity {
  margin-left: 4px;
}
.expiry-date {
  font-size: 0.9rem;
}
.bold {
  font-weight: bold;
}
</style>
