<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-center">
          <div><strong>Download Speed:</strong> {{ txSpeedTextValue }}</div>
          <div><strong>Upload Speed:</strong> {{ rxSpeedTextValue }}</div>
        </div>
        <apexchart
          type="line"
          :height="height"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    height: {
      type: Number,
      default: 200,
    },
    url: {
      type: String,
      default: null,
      required: true,
    },
    interfaceName: {
      type: String,
      default: null,
    },
  },
  data() {
    // Initialize an array for the first 6 data points with timestamps
    const initialDataRx = []
    const initialDataTx = []
    const currentTime = new Date().getTime()
    const interval = 2000 // 2 seconds interval as per your fetchData interval

    for (let i = 5; i >= 0; i--) {
      const time = currentTime - i * interval
      initialDataRx.push({ x: time, y: 0 })
      initialDataTx.push({ x: time, y: 0 })
    }

    return {
      rxSpeedTextValue: '0 bps',
      txSpeedTextValue: '0 bps',
      chartOptions: {
        chart: {
          type: 'line',
          height: 200,
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 2000,
            },
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          range: 10000,
          labels: {
            formatter: (value, timestamp) => new Date(timestamp).toLocaleTimeString(),
          },
        },
        yaxis: [
          {
            labels: {
              formatter: val => this.formatSpeed(val),
            },
          },
        ],
        tooltip: {
          x: {
            formatter: value => new Date(value).toLocaleTimeString(),
          },
        },
      },
      series: [
        { name: 'Download Speed', data: initialDataTx },
        { name: 'Upload Speed', data: initialDataRx },
      ],
      consecutiveFailures: 0,
      pendingRequests: 0,
      lastRxSpeed: 0,
      lastTxSpeed: 0,
    }
  },
  mounted() {
    this.checkPageVisibility()
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
    clearInterval(this.chartUpdateId)
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    async fetchData() {
      if (!this.url) {
        console.warn('Invalid url. Skipping fetchData.')

        return
      }
      if (this.pendingRequests >= 3) {
        console.warn('Max pending requests reached. Skipping fetchData.')

        return
      }
      this.pendingRequests++
      try {
        const customerData = await this.fetchDataFromAPI()
        if (customerData) {
          this.consecutiveFailures = 0
          this.lastRxSpeed = parseFloat(customerData['rx-bits-per-second'])
          this.lastTxSpeed = parseFloat(customerData['tx-bits-per-second'])

          this.rxSpeedTextValue = this.lastRxSpeed ? this.formatSpeed(this.lastRxSpeed) : '0 bps'
          this.txSpeedTextValue = this.lastTxSpeed ? this.formatSpeed(this.lastTxSpeed) : '0 bps'
        } else {
          this.consecutiveFailures++
          if (this.consecutiveFailures >= 3) {
            clearInterval(this.intervalId)
            clearInterval(this.chartUpdateId)
          }
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error)
      } finally {
        this.pendingRequests = Math.max(0, this.pendingRequests - 1)
      }
    },
    updateChartData() {
      this.addChartData(this.lastRxSpeed, this.lastTxSpeed)
    },
    addChartData(rxSpeed, txSpeed) {
      const currentTime = new Date().getTime()
      this.series[0].data.push({ x: currentTime, y: txSpeed || 0 })
      this.series[1].data.push({ x: currentTime, y: rxSpeed || 0 })
      this.series = [...this.series]
    },
    async fetchDataFromAPI() {
      try {
        const response = await axios.get(this.url)
        const data = this.interfaceName ? response.data[this.interfaceName][0] : response.data[0]

        return data
      } catch (error) {
        console.error('An error occurred while fetching data:', error)

        return null
      }
    },
    formatSpeed(bps) {
      const units = ['bps', 'Kbps', 'Mbps', 'Gbps']
      let unitIndex = 0
      let speed = bps

      while (speed >= 1000 && unitIndex < units.length - 1) {
        speed /= 1000
        unitIndex++
      }

      return `${speed.toFixed(2)} ${units[unitIndex]}`
    },
    checkPageVisibility() {
      if (document.visibilityState === 'visible' && !this.intervalId) {
        this.startDataFetchingInterval()
      } else if (document.visibilityState === 'hidden' && this.intervalId) {
        clearInterval(this.intervalId)
        clearInterval(this.chartUpdateId)
        this.intervalId = null
        this.chartUpdateId = null
      }
    },
    startDataFetchingInterval() {
      this.intervalId = setInterval(() => {
        this.fetchData()
      }, 2000)

      this.chartUpdateId = setInterval(() => {
        this.updateChartData()
      }, 2000)
    },
    handleVisibilityChange() {
      this.checkPageVisibility()
    },
  },
}
</script>
