<template>
  <v-card :min-height="210">
    <v-card-title>
      Internet Traffic Details
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <internet-traffic-details-graph :url="url" />
    </v-card-text>
  </v-card>
</template>

<script>
import InternetTrafficDetailsGraph from '../partials/InternetTrafficDetailsGraph.vue'

export default {
  components: {
    InternetTrafficDetailsGraph,
  },
  computed: {
    url() {
      return 'customer/internet-traffic-data'
    },
  },
}
</script>
